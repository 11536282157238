.galleryBanner {
  background-image: url("../../assets/workImg/24.jpg");
}
.galleryContainer {
  height: 100%;
  width: 100%;
  background-color: #fff;
}
.galleryContainerWrap {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
.galleryContent {
  padding: 1rem;
  margin-bottom: 5rem;
}
.galleryContentWrap {
  padding: 1rem;
}
.grid-3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3rem;
  align-items: center;
  justify-content: center;
}
.postContainer {
  width: 30vw;
  height: 100%;
  padding: 1rem;
}
.postContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
}
.postImgBox {
  width: 30vw;
  height: 60vh;
  background-color: transparent;
  position: relative;
  object-fit: contain;
  margin-bottom: 1rem;
}
.postImgBox img {
  width: 30vw;
  height: 60vh;
  object-fit: contain;
}
.postInfo {
  font-size: 1.4rem;
  text-align: center;
  padding: 0.4rem;
  font-style: italic;
}
.postInfo h4 {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 1.2px;
}
.postInfo span {
  display: block;
  padding: 0.4rem;
  font-weight: 500;
  color: var(--color-gold);
}
.imgWrapper {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  font-size: 1.2rem;
  border-radius: 1rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  opacity: 0.2;
}
.postImgBox:hover .imgWrapper {
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 1;
}
.logoText {
  text-align: center;
  margin-top: 2rem;
  letter-spacing: 1.8px;
  font-weight: 400;
}
.logoText span {
  color: var(--color-gold);
}

.activePostImage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  align-items: center;
}
.activeImgWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.logoWrapper {
  height: 90vh;
  display: flex;
  align-items: flex-end;
}

.activeImg {
  display: block;
  width: 60vw;
  height: 90vh;
  object-fit: contain;
}
.closeBtn {
  color: var(--color-white);
  position: absolute;
  top: 10vh;
  left: 2rem;
  font-size: 2.4rem;
  cursor: pointer;
  transition: var(--transition);
}
.closeBtn:hover {
  color: var(--color-gold);
  transition: var(--transition);
}
.arrow {
  color: var(--color-white);
  margin: 0 2rem;
  font-size: 2.4rem;
  cursor: pointer;
  transition: var(--transition);
}
.arrow:hover {
  color: var(--color-gold);
  transition: var(--transition);
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  .postContainer {
    width: 40vw;
    height: 75vh;
    padding: 1rem;
  }
  .postImgBox {
    width: 40vw;
    height: 55vh;
    background-color: var(--color-black);
  }
  .postImgBox img {
    width: 40vw;
    height: 55vh;
  }
}
@media screen and (max-width: 820px) {
  .postContainer {
    width: 50vw;
    height: 75vh;
    padding: 1rem;
  }
  .postImgBox {
    width: 50vw;
    height: 60vh;
    background-color: var(--color-black);
  }
  .postImgBox img {
    width: 50vw;
    height: 60vh;
  }
}
@media screen and (max-width: 425px) {
  .postContainer {
    width: 65vw;
    height: 75vh;
    padding: 1rem;
  }
  .postImgBox {
    width: 65vw;
    height: 55vh;
    background-color: var(--color-black);
  }
  .postImgBox img {
    width: 65vw;
    height: 55vh;
  }
}

/* */
