.sectionHeaderBox {
  text-align: center;
  padding-bottom: 4rem;
  margin-bottom: 2rem;
}
.sectionHeaderBox h2 {
  font-size: 4.2rem;
  color: var(--color-gray);
}
.sectionHeaderBox h3 {
  color: var(--color-gold);
  font-size: 2.4rem;
}
.sectionHeaderBox span {
  color: var(--color-gold);
}
.homeSectionWrapper {
  margin-top: 4rem;
  padding-top: 1rem;
}
/*Home Slide */
.homeSliderContainer {
  background: #fff;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}
.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}
.homeSlideContainer_1 {
  background-image: url("../../assets/img/IMG_6081.JPEG");
}
.homeSlideContainer_2 {
  background-image: url("../../assets/img/IMG_6116.JPEG");
}
.homeSlideContainer_3 {
  background-image: url("../../assets/img/IMG_6111.JPEG");
}
.homeSlideContainer_4 {
  background-image: url("../../assets/img/IMG_6129.JPEG");
}
.slideContent {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.slideContent .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  color: var(--color-white);
  border: 1px solid transparent;
  border-radius: 0;
  padding: 0;
}
.box .en .langOn {
  display: flex;
}
.slideContent h2 {
  font-size: 3.2rem;
  font-weight: 600;
}
.slideContent span {
  color: var(--color-gold);
}
.slideContent .imgInfo {
  position: absolute;
  font-size: 1rem;
  letter-spacing: 1px;
  top: 96vh;
  left: 80%;
}

.slideContent h1 {
  font-size: 5.4rem;
  font-weight: 500;
}
.slideContent .box p {
  font-size: 2.2rem;
  font-weight: 400;
  padding-top: 1rem;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button-next,
.swiper-button-prev {
  color: var(--color-white) !important;
}
.swiper-button-next:hover {
  color: var(--color-black) !important;
}
.swiper-button-prev:hover {
  color: var(--color-black) !important;
}
.swiper-pagination-bullet {
  background-color: var(--color-black);
}
.swiper-pagination-bullet-active {
  background-color: var(--color-white) !important;
}
.inner-box {
  margin-left: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.right {
  float: right;
  padding: 0 !important;
  font-size: 1.6rem !important;
}
.en .inner-box {
  margin-left: 0;
}
/* Home Slide End  */

/* Gallery Slide */
.homeGallerySliderContainer {
  background: transparent;
  color: #fff;
  margin: 0;
  padding: 0;
  height: 80vh;
  width: 100%;
}
.gallerySliderContainer .swiper-slide {
  background-color: #000;
  position: relative;
}
.gallerySlideContainer img {
  height: 80vh;
  width: 100%;
}
.gallerySlideContentWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 500ms linear;
}
.gallerySlideContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  letter-spacing: 2px;
  background-color: transparent;
  transition: all 500ms linear;
  border-left: 6px solid var(--color-white);
  border-right: 6px solid var(--color-white);
}
.gallerySlideContainer img {
  object-position: center;
}
.gallerySlideContent h4 {
  display: none;
  font-weight: 400;
}
.gallerySlideContent:hover h4 {
  display: block;
  padding-bottom: 0.5rem;
  color: var(--color-gold);
  transition: all 500ms linear;
  padding-bottom: 1rem;
}
.gallerySlideContent h3 {
  display: none;
  font-weight: 400;
}
.gallerySlideContent:hover h3 {
  display: block;
  position: sticky;
  transition: all 500ms linear;
}
.gallerySlideContent:hover {
  box-shadow: 0 50em 50em rgba(0, 0, 0, 0.6) inset;
}
.homeGallerySliderContainer.dual {
  display: none;
}
.homeGallerySliderContainer.single {
  display: none;
}
.homeGallerySliderContainer .en {
  display: none;
}
.homeGallerySliderContainer .en.langOn {
  display: flex !important;
}
.homeGallerySliderContainer .tr.langOn {
  display: flex !important;
}
/* Gallery Slide End */

/* Events Slide */
.homeEventsSliderContainer {
  background: #fff;
  color: #fff;
  margin: 0;
  padding: 0;
  height: 80vh;
  width: 100%;
}
.homeEventsSliderContainer.single {
  display: none;
}
.eventsSlideContainer .swiper-slide {
  background-color: #fff;
  position: relative;
}
.eventsSlideContainer img {
  height: 80vh;
  width: 49vw;
}
.eventsSlideContent {
  position: absolute;
  top: 0;
  width: 49vw;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 1.8rem;
  letter-spacing: 2px;
  background-color: rgba(0, 0, 0, 0.349);
  transition: all 400ms linear;
  border-left: 6px solid var(--color-white);
  border-right: 6px solid var(--color-white);
}
.slideContentWrapper {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  transition: all 500ms linear;
  padding: 4rem;
  width: 48vw;
  height: 100%;
}
.eventsSlideContent h4 {
  font-weight: 400;
  padding-bottom: 0.5rem;
  color: var(--color-gold);
}
.eventsSlideContent h3 {
  font-weight: 400;
  position: sticky;
}
.eventsSlideContent p {
  font-weight: 400;
  line-height: 2.2rem;
  font-size: 1.4rem;
  position: sticky;
  text-align: left;
}
.eventsSlideContent:hover {
  box-shadow: 0 50em 50em rgba(0, 0, 0, 0.6) inset;
}
.eventsSlideContent .slideContentWrapper.en {
  display: none;
}
.eventsSlideContent .slideContentWrapper.langOn {
  display: flex !important;
  align-items: flex-start;
  justify-content: center;
}
/* Events Slide End */

/* About */
.homeAboutContainer .homeSectionWrapper {
  margin-bottom: 5rem;
}
.aboutWrapper {
  display: flex;
  flex-direction: row;
  gap: 5rem;
  margin: 0 2rem;
  padding: 0 2rem;
  height: 100%;
}
.imgBox {
  width: 35vw;
  height: 70vh;
}
.imgBox img {
  width: 35vw;
  height: 100%;
}
.aboutWrapper .contentWrapper {
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aboutWrapper .contentBox {
  height: 50vh;
  text-align: left;
  font-size: 1.4rem;
  line-height: 2.6rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
}
.aboutWrapper h3 {
  padding-bottom: 1rem;
  font-size: 2rem;
  color: var(--color-gray);
  display: block;
}
.aboutWrapper p {
  display: block;
}
.readMore {
  padding: 1.4rem 0;
  display: block;
}

/* About End */

/* Media Queries */
@media screen and (max-width: 1024px) {
  .inner-box {
    margin-left: 0;
    margin-top: 1rem;
  }
  .en .inner-box {
    margin-left: 0;
  }
  .slideContent h2 {
    font-size: 2rem;
  }
  .slideContent h1 {
    font-size: 4.8rem;
  }
  .slideContent p {
    font-size: 1.6rem;
  }
  .slideContent .box {
    padding: 4rem 5rem;
  }
  .aboutWrapper {
    display: grid;
    grid-template-rows: 4fr 3fr;
    gap: 1rem;
  }
  .aboutWrapper .imgBox {
    margin-left: 0;
    width: 100%;
    text-align: center;
  }
  .aboutWrapper .imgBox img {
    margin-left: 0;
    width: 80%;
  }
  .aboutWrapper .contentBox h3 {
    text-align: center;
  }
  .aboutWrapper .contentBox p {
    font-size: 1.2rem;
  }
  .sectionHeaderBox h2 {
    font-size: 3.8rem;
  }
  .sectionHeaderBox h3 {
    font-size: 2rem;
  }
  .gallerySlideContent {
    font-size: 1.2rem;
  }
  .homeGallerySliderContainer.triple {
    display: none;
  }
  .homeGallerySliderContainer.dual {
    display: block;
  }
  .eventsSlideContent {
    font-size: 1.4rem;
  }
  .eventsSlideContent:hover p {
    font-size: 1.2rem;
    line-height: 2rem;
  }
  .eventsSlideContainer img {
    width: 70vw;
  }
  .eventsSlideContent {
    width: 70vw;
  }
  .slideContentWrapper {
    padding: 2rem;
    width: 70vw;
  }
}
@media screen and (max-width: 820px) {
  .inner-box {
    margin-left: 0;
    margin-top: 1rem;
  }
  .en .inner-box {
    margin-left: 0;
  }
  .slideContent h2 {
    font-size: 1.8rem;
  }
  .slideContent h1 {
    font-size: 4.32rem;
  }
  .slideContent p {
    font-size: 1.44rem;
    text-align: center;
  }
  .slideContent .imgInfo {
    left: 60%;
  }

  .aboutWrapper {
    display: grid;
    grid-template-rows: auto;
    gap: 1rem;
  }
  .aboutWrapper .imgBox {
    margin-top: 0;
    width: 100%;
    text-align: center;
    height: 100%;
  }
  .aboutWrapper .imgBox img {
    margin-left: 0;
    width: 80%;
  }
  .aboutWrapper .contentBox p {
    font-size: 1.2rem;
    padding-top: 2rem;
  }
  .readMore {
    padding: 0.6rem 0;
  }
  .sectionHeaderBox h2 {
    font-size: 3.4rem;
  }
  .sectionHeaderBox h3 {
    font-size: 1.78rem;
  }
  .homeEventsSliderContainer.single {
    display: block;
  }
  .homeEventsSliderContainer.dual {
    display: none;
  }
  .eventsSlideContent {
    border-left: none;
    border-right: none;
  }
  .gallerySlideContainer img {
    height: 70vh;
  }
  .homeGallerySliderContainer {
    height: 70vh;
  }
  .eventsSlideContainer img {
    width: 70vw;
  }
  .eventsSlideContent {
    width: 70vw;
  }
  .slideContentWrapper {
    padding: 2rem;
    width: 70vw;
    align-items: center;
  }
}
@media screen and (max-width: 425px) {
  .inner-box {
    margin-left: 0;
    margin-top: 1rem;
  }
  .en .inner-box {
    margin-left: 0;
  }
  .slideContent .box {
    width: 100vw;
    left: 0;
    align-items: center;
    border-radius: 0;
  }
  .slideContent .imgInfo {
    font-size: 0.8rem;
    left: 60%;
  }
  .homeGallerySliderContainer.dual {
    display: none;
  }
  .homeGallerySliderContainer.single {
    display: block;
  }
  .aboutWrapper {
    gap: 2rem;
    margin: 0 0.2rem;
    padding: 0 0.2rem;
  }
  .eventsSlideContent {
    font-size: 2rem;
  }
  .eventsSlideContent:hover p {
    font-size: 1.6rem;
  }
  .eventsSlideContainer img {
    width: 96vw;
  }
  .eventsSlideContent {
    width: 96vw;
  }
  .slideContentWrapper {
    padding: 2rem;
    width: 96vw;
    align-items: center;
  }
}
