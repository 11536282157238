.servicesBanner {
  background-image: url("../../assets/img/IMG_6081.JPEG");
}
.servicesContainer {
  height: 100%;
  width: 100%;
  background-color: #fff;
}
.servicesContainerWrap {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
.servicesContent {
  display: flex;
  flex-direction: column;
}
.servicesMenu {
  padding: 2rem 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
  gap: 4rem;
  margin-bottom: 5rem;
}
.serviceOption {
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
  gap: 2rem;
}
.serviceItem {
  padding: 1rem;
  margin: 1rem;
  height: 80vh;
  width: 35vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 1rem;
  transition: var(--transition);
}
.tourItem {
  background-image: url("../../assets/img/IMG_6129.JPEG");
}
.workshopItem {
  background-image: url("../../assets/img/Malzemeler\ ana\ sayfa\ fotosu.JPG");
}
.serviceItemWrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.serviceItemContent {
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 1.6px;
}
.serviceItemHeader {
  font-size: 3.2rem;
  font-weight: 600;
  letter-spacing: 2px;
}
.serviceItemSubHeader {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--color-gold);
}
.serviceItem:hover {
  padding: 6rem;
  transition: var(--transition);
}
.serviceItem a {
  font-size: 2.6rem;
}
.serviceItem a:hover {
  color: var(--color-gray);
}
.headerBox {
  text-align: center;
}
.serviceImgBox {
  padding: 2rem;
  text-align: center;
}
.serviceText h3 {
  text-align: center;
  font-size: 2.4rem;
  letter-spacing: 1.2px;
  color: var(--color-gray);
  font-weight: 500;
}
.serviceText {
  padding: 2rem 4rem;
}
.serviceText p {
  padding: 1rem 0;
  font-size: 1.4rem;
  letter-spacing: 1.2px;
  line-height: 2rem;
}
.serviceText span {
  font-size: 1.2rem;
  padding-bottom: 1rem;
  color: var(--color-white);
}
.serviceImgBox {
  width: 100%;
  height: 80vh;
}
.serviceImgBox img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 1px solid transparent;
  border-radius: 1rem;
}
#serviceForm {
  padding-bottom: 5rem;
}
.serviceForm label {
  color: #7b7b81;
  font-size: 1.3rem;
  font-weight: 400;
}
.radioContainer {
  display: flex;
  width: 40vw;
}
.radioContainer label {
  width: 100%;
  text-align: center;
}
.radioBox {
  width: 20vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radioContainer input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  padding: 1.2rem;
  width: 2em;
  height: 2em;
  margin-bottom: 0.4rem;
  background-color: var(--color-gray);
  border: 0.1rem solid var(--color-gold);
  border-radius: 50%;
  cursor: pointer;
}
input[type="radio"]:checked {
  box-shadow: 0 0 10px 0 var(--color-gold);
  background-color: var(--color-gold);
}
#workshop,
#serviceForm {
  background-color: rgb(17, 17, 17);
}
#workshop h3 {
  color: var(--color-gold);
}
#workshop p {
  color: var(--color-white);
}
.serviceDoubleImgBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  padding-top: 4rem;
}
.serviceDoubleImgBox img {
  width: 100%;
  height: 60vh;
  object-fit: contain;
}
#tour .serviceText .gold {
  color: var(--color-gold);
}
/* Media Queries */
@media screen and (max-width: 1024px) {
  .servicesMenu {
    padding: 1rem 2rem;
    gap: 2rem;
  }
  .serviceItem {
    padding: 1rem;
    margin: 1rem;
    width: 40vw;
  }
  .serviceItemHeader {
    font-size: 2.6rem;
  }
  .serviceItemSubHeader {
    font-size: 1.4rem;
  }
  .serviceItem:hover {
    padding: 4rem;
  }
  .serviceOption {
    padding: 2rem 4rem;
  }
  .serviceImgBox {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .serviceDoubleImgBox {
    flex-wrap: wrap;
    gap: 2rem;
    padding: 2rem;
    padding-top: 4rem;
  }
  .serviceDoubleImgBox img {
    width: 100%;
    height: 60vh;
    object-fit: contain;
  }
}
@media screen and (max-width: 820px) {
  .servicesMenu {
    padding: 1rem 0rem;
    gap: 2rem;
  }
  .serviceItem {
    padding: 1rem;
    margin: 1rem;
    width: 70vw;
    height: 50vh;
  }
  .serviceItemHeader {
    font-size: 2.6rem;
  }
  .serviceItemSubHeader {
    font-size: 1.4rem;
  }
  .serviceItem:hover {
    padding: 3rem;
  }
  .serviceOption {
    padding: 2rem 4rem;
    gap: 2rem;
  }
  .serviceImgBox {
    width: 100%;
    height: 100%;
    padding: 2rem;
  }
  .serviceText {
    padding: 2rem 2rem;
  }
  .radioContainer {
    width: 60vw;
  }
  .radioBox {
    width: 30vw;
  }
}
@media screen and (max-width: 450px) {
  .servicesMenu {
    padding: 1rem 0rem;
    gap: 2rem;
  }
  .serviceItem {
    width: 70vw;
    height: 50vh;
  }
  .serviceItem:hover {
    padding: 2.5rem;
  }
  .serviceOption {
    padding: 2rem 4rem;
    gap: 2rem;
  }
  .serviceImgBox {
    width: 100%;
    height: 100%;
    padding: 1rem;
  }
  .serviceText {
    padding: 2rem 2rem;
  }
  .radioContainer {
    width: 70vw;
  }
  .radioBox {
    width: 35vw;
  }
}
/* */
