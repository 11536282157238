.contactBanner {
  background-image: url("../../assets/img/IMG_1107.JPG");
}
.contactContainer {
  height: 100%;
  width: 100%;
  background-color: rgb(17, 17, 17);
  padding-bottom: 4rem;
}
.contactContainerWrap {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.contactContent {
  padding: 1rem;
  margin: 0.5rem;
}
.contactOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}

.headerBox h2{
  font-size: 4rem;
  color: var(--color-gold);
  letter-spacing: 2px;
  font-weight: 500;
}
form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
input,
textarea {
  width: 40vw;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-gold);
  resize: none;
  color: var(--color-white);
}
.sendBtn {
  width: max-content;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  font-weight: 500;
  border: 1px solid;
  border-radius: 4px;
  border-color: var(--color-dark);
  background-color: var(--color-gold);
  color: var(--color-dark);
  cursor: pointer;
  transition: var(--transition);
}
.sendBtn:hover {
  border-color: var(--color-gold);
  background-color: transparent;
  color: var(--color-gold);
}

.contactBoxes {
  padding: 0.8rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}
.contactBox {
  padding: 0.6rem;
  border: 1px solid var(--color-gold);
  border-radius: 0.5rem;
  background-color: var(--color-gray);
  width: 35rem;
  height: 20rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contactOption {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
}
.contactOption-icon {
  font-size: 2.5rem;
  color: var(--color-gold);
}
.contactOption p {
  font-size: 1.2rem;
  padding: 1rem;
}
.contactOption a {
  color: var(--color-gold);
  font-size: 1.2rem;
}

.warningBox {
  width: 70%;
  padding: 2rem 1rem;
  background-color: var(--color-black);
  color: var(--color-white);
  border: 1px solid var(--color-gold);
  border-radius: 0.5rem;
  text-align: center;
  font-size: 1.2rem;
}
.warningContent {
  padding: 1rem;
}
.warning-icon {
  color: var(--color-gold);
  font-size: 4rem;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  input,
  textarea {
    width: 60vw;
  }
}

@media screen and (max-width: 425px) {
  input,
  textarea {
    width: 70vw;
  }
  .contactBox {
    width: 30rem;
  }
  .headerBox h2{
    font-size: 3rem;
  }
}

/* */
