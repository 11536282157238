.eventsBanner {
  background-image: url("../../assets/img/AMERIKA\ 6.jpg");
}
.eventsContainer {
  height: 100%;
  width: 100%;
  background-color: #fff;
}
.eventsContainerWrap {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
.eventsContent {
  padding: 1rem 0rem;
  padding-bottom: 0;
  margin-bottom: 0;
}
.eventsContentWrap {
  padding: 1rem 0rem;
  padding-bottom: 0;
}
.grid-1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6rem;
  align-items: center;
  justify-content: center;
}
.eventContainer {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
}
.eventContainer:nth-child(even) {
  background-color: rgb(17, 17, 17);
  color: #fff;
  padding-top: 7rem;
}
.eventContainer:last-child {
  padding-bottom: 5rem
}
.eventContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.eventSliderContainer {
  width: 80vw;
  height: 100%;
}
.eventImgBox {
  width: 80vw;
  height: 100%;
  padding: 2rem;
  text-align: left;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-button-next {
  color: var(--color-gold) !important;
}
.swiper-button-prev {
  color: var(--color-gold) !important;
}
.eventImgBox img {
  width: 50vw;
  object-fit: cover;
  height: 70vh;
  border-radius: 1rem;
}
.eventInfoBox {
  padding: 1rem 2rem;
  padding-left: 0rem;
  height: auto;
  display: flex;
  align-items: flex-end;
}
.eventInfo {
  font-size: 1.3rem;
  padding: 1.2rem;
  padding: 2rem 4rem;
}
.eventInfo h4 {
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: 1.6px;
  padding-bottom: 0.6rem;
  text-align: center;
}
.eventInfo p {
  padding: 0rem 12rem;
  padding-bottom: 2rem;
  font-size: 1.4rem;
}
.eventInfo span {
  color: var(--color-gold);
  font-weight: 500;
  letter-spacing: 1.4px;
  font-size: 1.5rem;
  display: block;
  text-align: center;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  .eventContent {
    flex-direction: column;
  }
  .eventImgBox {
    width: 80vw;
    text-align: center;
  }
  .eventImgBox img {
    width: 80vw;
  }
  .eventInfoBox {
    align-items: flex-start;
    padding-left: 1rem;
    padding-top: 1rem;
    height: 100%;
  }
  .eventInfo p {
    padding: 0rem;
    padding-bottom: 2rem;
    font-size: 1.4rem;
  }
  .eventsBanner {
    background-position: 80% 10%;
  }
}

@media screen and (max-width: 425px) {
  .eventImgBox {
    width: 80vw;
    text-align: center;
    padding: 1rem;
  }
  .eventImgBox img {
    width: 80vw;
    height: 40vh;
  }
  .eventsBanner {
    background-position: 90% 10%;
  }
  .eventInfo {
    font-size: 1.3rem;
    padding: 1.2rem;
    padding: 2rem 2rem;
    letter-spacing: 1.5px;
    text-align: left;
  }
  .eventInfo h4 {
    display: block;
    text-align: center;
  }
}

/* */
