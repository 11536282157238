.navContainer {
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.699);
}
.navbar {
  width: 100%;
  height: 8.5rem;
  position: fixed;
  padding: 1rem 7rem;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 500ms ease;
  z-index: 999;
}
.navUnstable {
  background-color: var(--color-black);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  transition: all 500ms ease;
}
.navLogo {
  display: block;
}
.logoImgBox {
  width: 11rem;
  height: 7rem;
}
.logoImgBox img {
  width: 11rem;
  height: 7rem;
  object-fit: contain;
}
.navbar nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-left: 4rem;
  z-index: 999;
}
.navLink:not(.navButtonLink) {
  font-size: 1.6rem;
  letter-spacing: 2px;
  text-decoration: none;
  color: var(--color-white);
  font-weight: 400;
  padding: 0 2rem;
  text-transform: capitalize;
  transition: var(--transition);
}
.navLink:not(.logo):hover {
  color: var(--color-gold) !important;
}
.burger {
  display: none;
  color: var(--color-white);
}
.burgerIcon {
  font-size: 1.6rem;
  cursor: pointer;
  transition: all 300ms ease;
  color: var(--color-white);
  margin-top: 1rem;
}
.burgerIcon:hover {
  color: var(--color-gold);
  transform: rotateZ(90deg);
}
.burgerIconActive {
  font-size: 1.6rem;
  cursor: pointer;
  transition: all 300ms ease;
  color: var(--color-gold);
  transform: rotateZ(90deg);
}
.burgerIconActive:hover {
  color: var(--color-white);
  transform: rotateZ(0deg);
}
.mainNav.navActive {
  transform: translateX(0%);
}
.contactButton {
  padding: 1rem 2rem;
  font-size: 1.6rem;
  font-weight: 500;
  border: 1px solid;
  border-radius: 5px;
  border-color: var(--color-white);
  background-color: transparent;
  color: var(--color-white);
  cursor: pointer;
  transition: var(--transition);
  margin-left: 5rem;
}
.contactButton:hover {
  border-color: var(--color-gold);
  color: var(--color-gold);
}
.contactButton:hover.contactButton span {
  color: var(--color-gold);
  transition: var(--transition);
}
.contactButtonLink {
  text-decoration: none;
  color: unset;
}
.languageBox span {
  cursor: pointer;
}
.homeContainer .en {
  display: none;
}
.homeSliderContainer .en .langOn {
  display: flex !important;
}
/* Media Queries */
@media screen and (max-width: 1300px) {
  body {
    overflow-x: hidden;
  }
  .burger {
    display: block;
  }
  .navbar nav {
    position: absolute;
    right: 0;
    height: 100vh;
    top: 0;
    background-color: var(--color-black);
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    padding: 5rem 0;
    transition: var(--transition);
    transform: translateX(100%);
    font-size: 1rem;
  }
  .contactButton {
    margin: 0 2rem;
    padding: 1rem 0.5rem;
  }
}
@media screen and (max-width: 768px) {
  .logo span {
    font-size: 1.3rem;
  }
}
