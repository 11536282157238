.footerContainer {
  position: static;
  width: 100vw;
  padding: 4rem;
  background-color: var(--color-black);
  color: var(--color-white);
}
footer {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.contentWrap {
  padding: 2rem;
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.contentWrap .footerCol {
  padding: 0.4rem;
  font-size: 1.4rem;
}
.footerNavLink span:hover {
  color: var(--color-gold);
  transition: var(--transition);
}
.contentWrap a {
  color: unset;
}
.footerHeader {
  color: var(--color-gold);
  margin-bottom: 0.8rem;
  font-size: 2.4rem;
  font-weight: 400;
  border-bottom: 2px solid var(--color-gold);
}
.contentWrap ul {
  list-style-type: none;
  padding: 0.2rem 0;
}
.contentWrap li {
  line-height: 2.6rem;
  font-weight: 400;
  transition: var(--transition);
}
.contentWrap li:hover {
  color: var(--color-gold);
}
.footerLogo {
  height: 35vh;
}
.footerLogo img {
  height: 100%;
}
.footerSocials {
  padding-top: 2rem;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 4rem;
}
.footerSocials .socialBox {
  font-size: 2rem;
  transition: var(--transition);
  background-color: var(--color-gray);
  border-color: var(--color-gray);
  color: var(--color-white);
  padding: 0.8rem 0.8rem 0.4rem 0.8rem;
  border-radius: 5px;
  border: 1px solid;
}
.socialBox:hover {
  color: var(--color-gold);
  padding: 1rem 1rem 0.6rem 1rem;
}
.footerButton {
  margin-top: 1rem;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  letter-spacing: 2px;
  background-color: transparent;
  color: var(--color-white);
  border: 1px solid;
  border-color: var(--color-white);
  border-radius: 5px;
  transition: var(--transition);
}
.footerButton:hover {
  border-color: var(--color-gold);
  color: var(--color-gold);
}
.footerButton:hover.footerButton span {
    color: var(--color-gold);
    transition: var(--transition);
}
.footerCopyright {
  width: 100vw;
  height: 100%;
  padding: 2rem 2rem;
  background-color: var(--color-gray);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: var(--color-white);
  font-size: 1.2rem;
}
.footerCopyright span {
  color: var(--color-gold);
  padding: 0 0.6rem;
}

/* MEDIA QUERIES */

@media screen and (max-width: 820px) {
  .footerContainer {
    padding: 2rem;
  }
  footer {
    gap: 1.5rem;
  }
  .contentWrap {
    flex-wrap: wrap;
  }
  .contentWrap .footerCol {
    padding: 0.4rem;
    font-size: 1.1rem;
  }
  .footerHeader {
    font-size: 1.7rem;
  }
  .footerCopyright {
    font-size: 0.8rem;
  }
}
/* 460*/
@media screen and (max-width: 510px) {
  .footerContainer {
    padding: 1.5rem;
  }
  footer {
    gap: 1.5rem;
  }
  .contentWrap {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
  .contentWrap .footerCol {
    padding: 0.4rem;
    font-size: 1.2rem;
  }
  .footerHeader {
    font-size: 1.8rem;
  }
  .footerCopyright {
    font-size: 0.8rem;
  }
  .footerSocials {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
/* */
