.materialsBanner {
  background-image: url("../../assets/img/Malzemeler\ ana\ sayfa\ fotosu.JPG");
}
.materialsContainer {
  height: 100%;
  width: 100%;
  background-color: #fff;
}
.materialsContainerWrap {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
.materialsContent {
  padding: 1rem 0rem;
  padding-bottom: 0;
}
.materialsContentWrap {
  padding: 1rem 0rem;
  padding-bottom: 0;
}
.grid-1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6rem;
  align-items: center;
  justify-content: center;
}
.materialContainer {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
}
.materialContainer:nth-child(even) {
  background-color: rgb(17, 17, 17);
  color: #fff;
  padding-top: 7rem;
}
.materialContainer:last-child {
  padding-bottom: 5rem;
}
.materialContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.materialImgBox {
  width: 80vw;
  height: 100%;
  padding: 2rem;
  text-align: left;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: .5rem;
}
.materialImgBox img {
  width: 50vw;
  object-fit: cover;
  height: 70vh;
  border-radius: 1rem;
}
.materialInfoBox {
  padding: 1rem 2rem;
  padding-left: 0rem;
  height: auto;
  display: flex;
  align-items: flex-end;
}
.materialInfo {
  font-size: 1.3rem;
  padding: 1.2rem;
  padding: 2rem 8rem;
  letter-spacing: 1.5px;
}
.materialInfo h4 {
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: 1.6px;
  padding-bottom: 1rem;
  padding-top: 2.5rem;
  text-align: center;
}
.materialInfo p {
  padding: 0rem 12rem;
  padding-bottom: 2rem;
  font-size: 1.4rem;
}
.materialInfo span {
  color: var(--color-gold);
  font-weight: 500;
  letter-spacing: 1.4px;
  font-size: 1.5rem;
  display: block;
  text-align: center;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  .materialContent {
    flex-direction: column;
  }
  .materialImgBox {
    width: 80vw;
    text-align: center;
  }
  .materialImgBox img {
    width: 80vw;
  }
  .materialInfoBox {
    align-items: flex-start;
    padding-left: 1rem;
    padding-top: 1rem;
    height: 100%;
  }
  .materialInfo p {
    padding: 0rem;
    padding-bottom: 2rem;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 425px) {
  .materialImgBox {
    width: 50vw;
    text-align: center;
  }
  .materialImgBox img {
    width: 50vw;
    height: 40vh;
  }
  .materialInfo {
    font-size: 1.3rem;
    padding: 1.2rem;
    padding: 2rem 2rem;
    letter-spacing: 1.5px;
    text-align: center;
  }
  .materialInfo h4 {
    display: block;
    text-align: center;
  }
  .materialImgBox {
    width: 80vw;
    text-align: center;
    padding: 1rem;
  }
  .materialImgBox img {
    width: 80vw;
  }
}
