/* .aboutBanner {
    background-image: ;
} */
/* background-position: right 35% top 25%; */
.aboutBanner {
  background-image: url("../../assets/img/22.jpg");
  background-position: center;
}
/* CONTENT */
.aboutContainer {
  height: 100%;
  width: 100%;
  background-color: #fff;
}
.aboutContainerWrap {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
.aboutContent {
  padding: 1rem 2rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
}
.aboutInfo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 5rem;
  color: var(--color-black);
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}
.aboutInfo:nth-child(even) {
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.aboutInfo .aboutImgBox {
  padding: 0 2rem;
  height: 60vh;
  width: 40vw;
  object-fit: cover;
  object-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.aboutImgBox img {
  width: 30vw;
  height: 60vh;
  object-position: center;
  object-fit: contain;
}
.aboutImgBox .imgInfo {
  padding-top: 1rem;
  font-size: 1.2rem
}
.aboutInfo .aboutContentBox h3 {
  font-size: 2.4rem;
  letter-spacing: 2px;
  text-align: center;
}
.aboutInfo .aboutContentBox h4 {
  font-size: 1.6rem;
  letter-spacing: 1.6px;
}
.aboutInfo .aboutContentBox p {
  font-size: 1.6rem;
  line-height: 2.6rem;
  padding: 2rem 0;
  padding-bottom: 0;
  font-weight: 400;
}
.aboutInfo .aboutContentBox span {
  font-style: italic;
}
.aboutInfo .aboutContentBox {
  padding: 2rem 2rem;
}
.aboutInfo .textZone {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  line-height: 2.8rem;
  text-align: left;
}

/* */

/* Media Queries */
@media screen and (max-width: 1024px) {
  .aboutInfo {
    gap: 1rem;
    padding-bottom: 2rem;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 820px) {
  .aboutInfo {
    gap: 1rem;
    padding-bottom: 2rem;
    margin-bottom: 0;
  }
  .aboutInfo {
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 2rem;
    margin-bottom: 0;
  }
  .aboutInfo:nth-child(even) {
    flex-direction: column;
    width: 100vw;
  }
  .aboutInfo .aboutImgBox {
    padding: 0 2rem;
    height: 60vh;
    width: 80vw;
    object-fit: cover;
    object-position: center;
  }
  .aboutImgBox img {
    width: 80vw;
    height: 60vh;
    object-position: center;
  }
  .aboutInfo .aboutContentBox h3 {
    font-size: 2rem;
  }
  .aboutInfo .aboutContentBox p {
    font-size: 1.4rem;
    padding: 2rem 0;
    padding-bottom: 0;
    font-weight: 400;
  }
  .aboutInfo:nth-child(even) {
    width: 100%;
  }
  .aboutInfo:nth-child(2) .aboutContentBox p {
    font-size: 1.2rem;
    padding: 2rem 0;
    padding-bottom: 0;
    font-weight: 400;
  }
}
@media screen and (max-width: 425px) {
  .aboutInfo .aboutImgBox {
    padding: 0 2rem;
    height: 40vh;
    width: 90vw;
    object-fit: cover;
    object-position: center;
  }
  .aboutImgBox img {
    width: 80vw;
    height: 40vh;
    object-position: center;
  }
}

/* */
