:root {
  --color-black: #000000;
  --color-gray: #212121;
  --color-white: #f0f0f0;
  --color-gold: #d9bb73;

  --transition: all ease 300ms;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  font-family: "Poppins", "Open Sans", sans-serif;
}
body {
  background-color: #fff;
  overflow-x: clip;
}
html {
  font-size: 10px;
  overflow-x: clip;
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  color: unset;
}
.gold {
  color: var(--color-gold);
}
.imgInfo.light {
  color: rgb(202, 202, 202);
}
.imgInfo.dark {
  color: var(--color-gray);
}
.italic {
  font-style: italic;
}
.bold {
  font-weight: bold;
}
*::-webkit-scrollbar {
  height: 0.5rem;
  width: 1rem;
}
*::-webkit-scrollbar-track {
  background-color: var(--color-gray);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--color-gold);
  border-radius: 5px;
}
.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Banner General */
.banner {
  height: 100vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bannerWrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.25);
  position: relative;
}
.bannerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: left;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  color: var(--color-white);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 4rem 6rem;
}
.bannerContent h2 {
  font-size: 3.2rem;
  font-weight: 600;
}
.bannerContent span {
  color: var(--color-gold);
}
.bannerContent h1 {
  font-size: 5.4rem;
  font-weight: 500;
}
.bannerContent p {
  font-size: 4rem;
  font-weight: 400;
  padding-top: 1rem;
  transition: var(--transition);
  cursor: pointer;
}
.bannerContent p:hover {
  color: var(--color-gold);
  transition: var(--transition);
}

/* Translate */
.en {
  display: none;
}
.langOff {
  display: none !important;
}
.langOn {
  display: block !important;
}
.banner .langOn {
  display: flex !important;
}
.materialInfo .langOn {
  display: flex !important;
}

/* */

@media screen and (max-width: 425px) {
  .bannerContent h2 {
    font-size: 2.2rem;
    font-weight: 600;
  }
  .bannerContent h1 {
    font-size: 5rem;
    font-weight: 500;
  }
  .bannerContent p {
    font-size: 4rem;
    font-weight: 400;
    padding-top: 1rem;
    transition: var(--transition);
    cursor: pointer;
  }
}
